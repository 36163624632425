import React from "react";
import css from './index.module.css';
import Header from "../header";
import Footer from "../footer";

const MainContent = ({ children }) => {
  return (
    <div className={css.tartikov}>
      <Header />
      <div className={css.tartikov_body}>
        <div className={css.tartikov_body_content}>
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MainContent;