import React, { useState, useRef, useEffect } from 'react';
import css from './index.module.css';
import axios from 'axios';
import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';

export default function Popup ({ showModal, setShowModal }) {
  const [sended, setSended] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    axios.post(`https://cdn.leecyber.com/tartikov/index.php`, formData, {
        headers: {
            'Content-Type': 'application/json'
        }})
        .then(response  => {
            toast.success(t('popup.notify'));
            event.target.reset();
            localStorage.setItem('sendedForm', true);
            setShowModal(false);
            setSended(true);
        })
        .catch(error => {
            toast.error(t('popup.error'));
    });
  };

  return (
    <div className={showModal ? css.popup : `${css.popup} ${css.hidden}`}>
        <div className={css.bpopup} onClick={() => setShowModal(false)}></div>
        <div className={css.pblock}>
            {sended || localStorage.getItem('sendedForm') && localStorage.getItem('sendedForm') === true ? 
                <h6 className={css.header}>{t('popup.sended')} 👀</h6>
             :    
                <form onSubmit={handleSubmit} className="form" method="post">
                    <h6 className={css.header}>{t('popup.send')}</h6>
                    
                    <div className={css.inputBlock}>
                        <label for="name">{t('popup.name')}</label>
                        <input type='text' placeholder={t('popup.name')} name='name' required/>
                    </div>

                    <div className={css.inputBlock}>
                        <label for="contact">{t('popup.connect')}</label>
                        <input type='text' placeholder={t('popup.tg')} name='contact' required/>
                    </div>

                    <div className={css.inputBlock}>
                        <label for="project">{t('popup.what')}</label>
                        <textarea type='text' placeholder={t('popup.desc')} name='project' required/>
                    </div>

                    <button className={css.sendBtn} type="submit">{t('popup.send')}</button>
                    <a style={{ fontSize: 12 }}>{t('popup.policy')}</a>
                </form>
            }
        </div>
    </div>
  );
}