import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsInEng from './en/translation.json';
import translationsInRus from './ru/translation.json';
import translationsInCn from './cn/translation.json';

import translationsInDe from './de/translation.json';
import translationsInJp from './jp/translation.json';
import translationsInEs from './es/translation.json';

const resources = {
  en: {
    translation: translationsInEng
  },
  ru: {
    translation: translationsInRus
  },
  cn: {
    translation: translationsInCn
  },
  es: {
    translation: translationsInEs
  },
  jp: {
    translation: translationsInJp
  },
  de: {
    translation: translationsInDe
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "ru",
    debug: false,
    fallbackLng: "ru",
    interpolation: {
      escapeValue: false
    },
    ns: "translation",
    defaultNS: "translation"
  });

export default i18n;