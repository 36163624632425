import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import css from './index.module.css';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className={css.tartikov_footer}>
        <div className={css.tartikov_footer_main}>
            <div className={css.tartikov_footer1}>
                <a>Тимур Артиков</a>
                <div>
                    <a href={'#projects'}>{t('menu.projects')}</a>
                    <a href={'#competition'}>{t('menu.about')}</a>
                    <a href={'#reviews'}>{t('menu.reviews')}</a>
                    <a href={'#contacts'}>{t('menu.contacts')}</a>
                </div>
            </div>

            <div className={css.tartikov_footer2}>
                <a>© 2024 Тимур Артиков. {t('menu.copy')}</a>

                <div>
                    <a href="mailto:t@tartikov.ru" target="_blank" rel="noopener noreferrer">t@tartikov.ru</a>
                    <a href="https://t.me/mr_artikov" target="_blank" rel="noopener noreferrer">@mr_artikov</a>
                </div>
            </div>
        </div>
    </footer>
  )
}