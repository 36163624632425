import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import css from './index.module.css';
import sign from '@assets/signature.svg';
import Popup from "../contact";
import { useTranslation } from 'react-i18next';
import i18n from '@locales/index';

import flagcn from '@assets/flags/cn.svg';
import flagen from '@assets/flags/en.svg';
import flagde from '@assets/flags/de.svg';
import flages from '@assets/flags/es.svg';
import flagjp from '@assets/flags/jp.svg';
import flagru from '@assets/flags/ru.svg';

export default function Header() {
  const [isHovered, setIsHovered] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [popup, setPopup] = useState(false);
  const { t } = useTranslation();
  const timeoutRef = useRef(null);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  const chooseLanguage = (e) => {
    const language = e;
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    localStorage.setItem('selectedLanguage', language);
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsHovered(false);
    }, 100);
  };

  return (
    <>
      <Popup showModal={popup} setShowModal={setPopup} />
      <header className={css.tartikov_header}>
        <div className={css.leecyberKit_header_body}>
          <img src={sign} width={120} height={"100%"}/>
          
          <div className={`${css.leecyberKit_header_links} dev-hidden-mobile`}>
            <a href={'#competition'}>{t('menu.about')}</a>
            <a href={'#projects'}>{t('menu.projects')}</a>
            <a className={css.contactLink} onClick={() => setPopup(true)}>{t('menu.contacts')} <i className="fi fi-rr-arrow-up-right"></i></a>
            <i 
              className="fi fi-rr-globe" 
              onMouseEnter={handleMouseEnter} 
              onMouseLeave={handleMouseLeave}
            ></i>
          </div>

          <div className={`${css.mobileMenu} dev-hidden-desktop dev-hidden-tablet`}>
            <Link className={`${css.contactLink}`} onClick={() => setPopup(true)}>{t('menu.contacts')} <i className="fi fi-rr-arrow-up-right"></i></Link>
            <i 
              className="fi fi-rr-globe" 
              onMouseEnter={handleMouseEnter} 
              onMouseLeave={handleMouseLeave}
            ></i>
          </div>

          {isHovered && (
            <div 
              className={css.langMenu} 
              onMouseEnter={handleMouseEnter} 
              onMouseLeave={handleMouseLeave}
            >
              <a key={'ru'} onClick={() => {chooseLanguage('ru')}} className={selectedLanguage === 'ru' ? css.active : ''}><img src={flagru} className={css.flagIcon} />RU</a>
              <a key={'en'} onClick={() => {chooseLanguage('en')}} className={selectedLanguage === 'en' ? css.active : ''}><img src={flagen} className={css.flagIcon} />EN</a>
              <a key={'es'} onClick={() => {chooseLanguage('es')}} className={selectedLanguage === 'es' ? css.active : ''}><img src={flages} className={css.flagIcon} />ES</a>
              <a key={'cn'} onClick={() => {chooseLanguage('cn')}} className={selectedLanguage === 'cn' ? css.active : ''}><img src={flagcn} className={css.flagIcon} />CN</a>
              <a key={'de'} onClick={() => {chooseLanguage('de')}} className={selectedLanguage === 'de' ? css.active : ''}><img src={flagde} className={css.flagIcon} />DE</a>
              <a key={'jp'} onClick={() => {chooseLanguage('jp')}} className={selectedLanguage === 'jp' ? css.active : ''}><img src={flagjp} className={css.flagIcon} />JP</a>
            </div>
          )}
        </div>
      </header>
    </>
  )
}