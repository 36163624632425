import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import css from './index.module.css';

export default function ProjectBlock({ name, mName, desc, img, tag, link }) {
  return (
    <a className={css.projectBlock} href={link} target="_blank" rel="noopener noreferrer">
      <div className={css.tag}>{tag}</div>
      <img src={img} alt={name} />
      
      <h3>{name}<a> / {mName}</a> <i class="fi fi-rr-arrow-up-right dev-hidden-mobile"></i></h3>
      <a>{desc}</a>
    </a>
  )
}