import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import css from './index.module.css';

export default function ReviewBlock({ review, name, tag, link, logo }) {
    return (
        <div className={css.reviewBlock}>
            <a className={css.reviewText}>«{review}»</a>
            
            <Link to={link} className={css.authorInfo}>
                <a className={css.reviewAuthor}>{name} <img src={logo} width={25} style={{ float: 'right', borderRadius: 5, cursor: 'pointer' }}/></a>
                <p className={css.reviewAuthorTag}>{tag}</p>
            </Link>
        </div>
    )
}