import React from 'react';
import Marquee from "react-fast-marquee";
import vk from '@assets/companies/vk.svg';
import alfa from '@assets/companies/alfa.svg';
import bc from '@assets/companies/bc.svg';
import xfire from '@assets/companies/xfire.png';
import sweetcat from '@assets/companies/sweetcat.svg';
import leecyber from '@assets/companies/leecyber.png';
import css from './index.module.css';

const MarqueeLogos = () => {
  return (
    <Marquee direction="left" autoFill={true} style={{ marginTop: 30 }}>
        <img src={vk} width={150} className={css.logo}/>
        <img src={alfa} width={40} className={css.logo}/>
        <img src={bc} width={40} className={css.logo}/>
        <img src={leecyber} width={150} className={css.logo}/>
        {/* <img src={xfire} width={100} className={css.logo}/>
        <img src={sweetcat} width={40} className={css.logo}/> */}
    </Marquee>
  );
};

export default MarqueeLogos;
